import styled from 'styled-components'
import { shadow5, tertiaryYellow, white } from 'tcp-common/lib/colors'
import Flex from 'tcp-common/lib/components/Flex'
import { greyBackgroundGradient } from 'tcp-common/lib/gradient'

export const MainContent = styled(Flex)`
@media (max-width: 768px) {
    margin-top: 16px;
}
`
export const LeftContent = styled.span`
font-weight: 600;
font-size: 32px;
line-height: 48px;
text-align: center;
color: ${tertiaryYellow};
margin-bottom: 12px;
@media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
}
`
export const RightContent = styled.span`
font-weight: 600;
font-size: 32px;
line-height: 48px;
text-align: center;
color: ${tertiaryYellow};
margin-bottom: 12px;
@media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
}
`
export const CenterContentMain = styled.span`
font-weight: 400;
font-size: 32px;
line-height: 28px;
color: ${white};
margin-bottom: 12px;
@media (max-width: 768px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}
`
export const CenterContentSecondary = styled.span`
font-weight: 500;
font-size: 28px;
line-height: 29px;
color: ${white};
margin-bottom: 12px;
@media (max-width: 768px) {
    font-size: 16px;
    line-height: 17px;
}
`
export const CenterContent = styled(Flex)`
text-align: center;
align-self: center;
margin: 0 50px;
@media (min-width: 768px) {
    width: 40%;
}
`
export const LeftBox = styled(Flex)`
width: 20%;
padding-left: 20px;
align-self: center;
@media(min-width: 768px){
margin-left:32px;
}
@media (max-width: 768px) {
width: 40%;
padding-left: 0;
}
`
export const RightBox = styled(Flex)`
width: 20%;
align-self: center;
@media (max-width: 768px) {
    width: 40%;
}
`
export const CardImage = styled.img`
margin: 30px 36px;
background: ${shadow5};
border-radius: 20px;
@media (max-width: 768px) {
    width: 93%;
    margin: auto;
}
`
export const BrandsContainer=styled(Flex)`
border-radius:16px;
background:${greyBackgroundGradient};
margin:16px;
@media (min-width: 768px){
    margin:24px;
}
`
export const LogoCell=styled.div`
display:flex;
flex-direction:row;
flex-wrap:wrap;
width:100%;
padding:24px;
row-gap:24px;
column-gap:80px;
justify-content:center;
@media (max-width: 768px){
column-gap:60px;
}
img{
border-radius:50%;
width:52px;
height:52px;
object-fit:cover;
@media (max-width: 768px){
width:40px;
height:40px;
}
}
`