import React, { useEffect, lazy, Suspense } from "react"
import setupAnalytics, {
    callPageLoadEvents,
    setPageInfo
} from "../utility/Analytics"
import { returnPlatform } from "../utility/getPlatform"
import { Skeleton } from "@mui/material"
import MainSection from "../MainSection"
import RewardsSection from "../RewardsSection"
import CardInfoSection from "../CardInfoSection"
import BenefitsSection from "../BenefitsSection"
import DetailsSection from "../DetailsSection"
const ApplySection=lazy(()=>import("../ApplySection"))
const FeesSection = lazy(() => import("../FeesSection"))
const CalculatorWidget = lazy(() => import("../CalculatorWidget"))
const SavingsSection = lazy(() => import("../SavingsSection"))
const FAQS = lazy(() => import("../FAQs"))
const TermsAndConditions = lazy(() => import("../TermsAndConditions"))
const Footer = lazy(() => import("../Footer"))
const EligibilitySection=lazy(()=>import("../EligibilitySection"))
const VideoSection=lazy(()=>import("../VideoSection"))
const HowToApplySection=lazy(()=>import("../HowToApply"))

function CreditCardPage() {
    useEffect(() => {
        setupAnalytics()
        setPageInfo({
            pageName: "creditcardmicrosite",
            category: "Credit Card"
        })
        callPageLoadEvents({
            pageTitle: "creditcardmicrosite",
            pageCategory: "Credit Card",
            platform: returnPlatform(),
            currentPath: window?.location?.href
        })
    }, [])
    return (
        <>
            <MainSection />
            <CardInfoSection />
            <RewardsSection />
            <DetailsSection />
            <BenefitsSection/>
            <Suspense
                fallback={<Skeleton variant="rectangular" height={100} />}>    
                <ApplySection title="Upgrade to Tata Neu HDFC Bank Credit Card for extraordinary benefits and privileges"/>
                <SavingsSection />
                <VideoSection />                           
                <EligibilitySection/>               
                <HowToApplySection/>               
                <CalculatorWidget />
                <FeesSection />
                <TermsAndConditions />
                <ApplySection title="Apply to Tata Neu HDFC Bank Credit Card Now!" background="#2A2A2A" centered="true"/>
                <FAQS />
                <Footer />
            </Suspense>
        </>
    )
}
export default CreditCardPage
