import React from 'react'
import {  
  RewardContent, 
  RewardImage, 
  RewardWrapper, 
  RightContent, 
  RupeeEqual, 
  RupeeView, 
  SmallText, 
  Subheader,
  Subheader1,
  Title
} from './styles';
import isMobile from 'tcp-common/lib/utils/is-mobile'

const RewardsSection = () => {
  const isMobileView = isMobile()

const renderDesktopView = () => <RewardWrapper flexDirection='column' id="benefits">
        <RewardContent flexDirection='row'>
             <RewardImage 
                    src='./Microsite Wireframe Desktop/Neucard-banner-2-500X500.webp'
                    alt='Shopping with Tata Neu Credit Cards'
                    width="500"
                    height="500"
                ></RewardImage>
            <RightContent flexDirection='column'>
            <Title>Experience ultimate rewards with NeuCard</Title>
            <SmallText>Get up to 5% NeuCoins on Tata Neu</SmallText>
                <Subheader>#NeuWayToRewards</Subheader>
                <Subheader>Ready for you.</Subheader>
                <RupeeView><Subheader1><img
              src={"./images/neucoin.svg"}
              alt="neucoin"
              style={{ marginRight: "16px" }}
            />1 NeuCoin&nbsp;</Subheader1> <RupeeEqual>=</RupeeEqual> <Subheader1>&nbsp;₹1</Subheader1></RupeeView>
            </RightContent>
        </RewardContent>
    </RewardWrapper>


const renderMobileView = () => <RewardWrapper flexDirection='column'>
        <RewardImage 
            src='./Microsite Wireframe Mobile/Neucard Mobile banner 2 - 360x360.webp'
            alt='Shopping with Tata Neu Credit Cards'
            width="360"
            height="360"
        ></RewardImage>
        <RightContent flexDirection='column'>
        <Title>Experience ultimate rewards with NeuCard</Title>
            <SmallText>Get upto 10% NeuCoins on Tata Neu</SmallText>
            <Subheader>#NeuWayToRewards</Subheader>
                <Subheader>Ready for you.</Subheader>
            <RupeeView><img
              src={"./images/neucoin.svg"}
              alt="neucoin"
              style={{ marginRight: "16px",marginTop:"16px" }}
            /><Subheader1>1 NeuCoin&nbsp;</Subheader1> <RupeeEqual>=</RupeeEqual> <Subheader1>&nbsp;₹1</Subheader1></RupeeView>
        </RightContent>
    </RewardWrapper>

    const renderView = () => isMobileView ? renderMobileView() : renderDesktopView()
    
    return renderView();
}

export default RewardsSection
