import styled from 'styled-components'
import { black, tertiaryYellow, white } from 'tcp-common/lib/colors'
import Flex from 'tcp-common/lib/components/Flex'

export const MainWrapper = styled(Flex)`
background-color: ${black};
width:100%;
@media (min-width: 768px) {
    margin: 0 auto; 
}
`
export const MainContent = styled(Flex)`
margin: 0px auto 64px auto;
justify-content:center;
align-items:center;
max-width:1024px;
width:100%;
@media (min-width: 768px){
padding:0 16px;}
@media (max-width: 768px){
    max-width:100%;
    }
`
export const LeftContent = styled(Flex)`
max-width:50%;
text-align: left;
@media (max-width: 768px) {
    max-width:100%;
    padding:16px 16px 24px;
}
`
export const RightContent = styled(Flex)`
max-width: 50%;
`
export const Title = styled.h1`
font-weight: 700;
font-size: 40px;
line-height: 48px;
color: ${white};
margin-bottom: 16px;
padding-right:16px;
@media (max-width: 768px) {
    font-size: 32px;
    line-height: 38px;
    padding-right:0px;
}
`
export const Subheader = styled.span`
font-weight: 700;
font-size: 28px;
line-height: 36px;
color: ${tertiaryYellow};
margin-bottom: 16px;
@media (max-width: 768px) {
    font-size: 20px;
line-height: 32px;
margin-bottom:0px;
}
`
export const SmallText = styled.span`
font-weight: 400;
font-size: 20px;
line-height: 24px;
color: #CCCCCC;
margin-bottom:24px;
@media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom:0px;
}
`
export const MainImage = styled.img`
max-width: 100%;
width: 100%;
height: auto;
`
export const ButtonText = styled.span`
margin-right: 8px;
`
export const InterestedButton = styled.button`
background: #8241FF;
border-radius: 20px;
height: 40px;
border: none;
text-align: center;
font-size: 16px;
line-height: 24px;
color: ${white};
cursor: pointer;
outline: none;
width: 100%;
&:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
    max-width: 276px;
    display: block;
   
}
`
export const FixedButton = styled.button`
background: #8241FF;
border-radius: 20px;
height: 40px;
border: none;
text-align: center;
font-size: 16px;
line-height: 24px;
color: ${white};
cursor: pointer;
outline: none;
&:focus {
  outline: none;
}
width: 100%;
@media screen and (min-width: 768px) { 
   
}
`
export const ButtonContainer = styled(Flex)`
padding:16px;
background-color: #1E1E1E;
width:100%;
max-width:1024px;
`
export const Wrapper = styled(Flex)`
width:100%;
justify-content:center;
background-color: #1E1E1E;
z-index: 1200;
position:fixed;
bottom:0px;   
transition: bottom 0.3s ease-in-out;
`