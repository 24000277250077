import React from 'react'
import DOMPurify from 'dompurify'
import { 
    MainContent, 
    RightContent, 
    LeftContent,
    CenterContentMain,
    CenterContentSecondary,
    CenterContent,
    LeftBox,
    RightBox,
    BrandsContainer,
    LogoCell
} from './styles';
import isMobile from 'tcp-common/lib/utils/is-mobile'
import { brands } from '../constant';

const Card = (props) => {
    const isMobileView = isMobile()

    const renderDesktopView = () => <>
        <MainContent flexDirection='row' justifyContent='space-around'>
            <LeftBox justifyContent='center'>
                <LeftContent>{props.left}</LeftContent>
            </LeftBox>
            <CenterContent flexDirection='column' justifyContent='space-between'>
                <CenterContentMain>{props.centerMain}</CenterContentMain>
                {props.centerSecondary && <CenterContentSecondary>{props.centerSecondary}</CenterContentSecondary>}    
            </CenterContent>
            <RightBox justifyContent='center'>
                <RightContent>{props.right}</RightContent>
            </RightBox>
        </MainContent>
        {props.centerImage && <BrandsContainer>
            <LogoCell>{brands.map((b,i)=><div key={i} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(b)}}/>)}</LogoCell>
        </BrandsContainer>}
    </>

    const renderMobileView = () => <>
        <CenterContent flexDirection='column' justifyContent='space-between'>
            <CenterContentMain>{props.centerMain}</CenterContentMain>
            {props.centerSecondary && <CenterContentSecondary>{props.centerSecondary}</CenterContentSecondary>}    
        </CenterContent>
        <MainContent flexDirection='row' justifyContent='space-around'>
            <LeftBox justifyContent='center'>
                <LeftContent>{props.left}</LeftContent>
            </LeftBox>
            <RightBox justifyContent='center'>
                <RightContent>{props.right}</RightContent>
            </RightBox>
        </MainContent>
        {props.centerImage && <BrandsContainer>
            <LogoCell>{brands.map((brand,idx)=><div key={idx} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(brand)}}/>)}</LogoCell>
        </BrandsContainer>}
    </>

    const renderView = () => isMobileView ? renderMobileView() : renderDesktopView()
    
    return renderView();
}

export default Card
