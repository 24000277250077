import React from 'react'
import {  
  RewardContent, 
  RewardImage, 
  RewardWrapper, 
  RightContent, 
  SmallText, 
  Subheader,
  Title
} from './styles';
import isMobile from 'tcp-common/lib/utils/is-mobile'

const CardInfoSection = () => {
  const isMobileView = isMobile()

const renderDesktopView = () => <RewardWrapper flexDirection='column'>
        <RewardContent flexDirection='row'>            
            <RightContent flexDirection='column'>
            <Title>What is NeuCard?</Title>
            <SmallText>Enter the world of rewards and savings with the Tata Neu HDFC Bank Credit Card – powered by RuPay/Visa. Unlock savings of up to 10% and exclusive offers across fashion, groceries, electronics, medicines, flights and much more with NeuCard. Travel in style with complementary airport lounge access plus exclusive offers on hotel stay and dining! Apply now and enjoy unmatched privileges. </SmallText>
                <Subheader>Extraordinary rewards. Ready for you</Subheader>
            </RightContent>
            <RewardImage 
                    src='./Microsite Wireframe Desktop/WhatIsNeucardDesktop.webp'
                    alt='Shopping with Tata Neu Credit Cards'
                    width="500"
                    height="500"
                ></RewardImage>
        </RewardContent>
    </RewardWrapper>


const renderMobileView = () => <RewardWrapper flexDirection='column'>
        <RewardImage 
            src='./Microsite Wireframe Mobile/WhatIsNeuCardMobile.webp'
            alt='Shopping with Tata Neu Credit Cards'
            width="360"
            height="360"
        ></RewardImage>
        <RightContent flexDirection='column'>
        <Title>What is NeuCard?</Title>
            <SmallText>Enter the world of rewards and savings with the Tata Neu HDFC Bank Credit Card – powered by RuPay/Visa. Unlock savings of up to 10% and exclusive offers across fashion, groceries, electronics, medicines, flights and much more with NeuCard. Travel in style with complementary airport lounge access plus exclusive offers on hotel stay and dining! Apply now and enjoy unmatched privileges.</SmallText>
            <Subheader>Extraordinary rewards. Ready for you</Subheader>
        </RightContent>
    </RewardWrapper>

    const renderView = () => isMobileView ? renderMobileView() : renderDesktopView()
    
    return renderView();
}

export default CardInfoSection
