import React,{lazy} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { greyBackgroundGradient } from 'tcp-common/lib/gradient'
import CreditCardPage from './CreditCardInfo/CreditCardPage';
const RewardsCalculator=lazy(() => import("./CreditCardInfo/RewardsCalculator"));

function App() {
  return (
    <div style={{
      background: greyBackgroundGradient
    }}>
      <BrowserRouter>
      <Routes>
          <Route path="/creditcard" element={<CreditCardPage/>}/>
          <Route path="/credit-card" element={<CreditCardPage/>}/>
          <Route path="/creditcard/rewardscalculator" element={<RewardsCalculator/>}/>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
