import { useEffect } from 'react'

/* eslint-disable */
export const useMount = callback => {
  useEffect(() => {
    if (typeof callback !== 'function') {
      throw new Error('callback should be a valid function')
    }
    callback()
  }, [])
}
/* eslint-enable */
