import styled from 'styled-components'
import { black, disabledGrey, white } from 'tcp-common/lib/colors'
import Flex from 'tcp-common/lib/components/Flex'
import { greyBackgroundGradient } from 'tcp-common/lib/gradient'

export const MainWrapper = styled(Flex)`
background-color: ${black};
justify-content:center;
align-items:center;
width:100%;
padding-bottom: 32px;
@media (min-width: 768px) {
    margin:  0 auto;
    padding-bottom: 0; 
}
`
export const MainContent = styled(Flex)`
margin:40px auto 118px;
max-width:1024px;
width:100%;
@media (max-width: 768px){
    max-width:100%;
}
`
export const DetailsContent = styled(Flex)`
background: ${greyBackgroundGradient};
border-radius: 0px 0px 20px 20px;
@media (max-width: 768px) {
    border-radius: 0px;
}
`
export const RightContent = styled(Flex)`
margin: 64px 150px 64px 0
`
export const DetailContentHeader = styled(Flex)`
padding: 30px 42px 90px;

@media (max-width: 768px) {
    padding: 16px 16px 32px;
}
`
export const DetailHeader = styled.span`
font-weight: 700;
font-size: 36px;
line-height: 44px;
color: ${white};
margin-bottom: 12px;
@media (max-width: 768px) {
    font-size: 30px;
    line-height: 24px;
    font-weight: 600px;
}
`
export const DetailHeaderLeft = styled.span`
font-weight: 600;
font-size: 36px;
line-height: 44px;
color: ${white};
margin-bottom: 12px;
@media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
}
`
export const DetailHeaderRight = styled.span`
font-weight: 600;
font-size: 36px;
line-height: 44px;
color: ${white};
margin-bottom: 12px;
@media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px
}
`
export const SmallText = styled.span`
font-weight: 400;
font-size: 24px;
line-height: 36px;
color: ${white};
@media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
}
`
export const SectionImage = styled.img`
max-width: 100%;
height:auto;
width:100%;
`
export const ButtonText = styled.span`
margin-right: 8px;
color: ${white};
`
export const InterestedButton = styled.button`
background: #8241FF;
border-radius: 20px;
height: 40px;
border: none;
text-align: center;
font-size: 16px;
line-height: 24px;
color: #FFFFFF;
cursor: pointer;
outline: none;
&:focus {
  outline: none;
}
margin: auto 60px;
margin-top: 32px;
margin-bottom: 64px;
position: relative;
@media screen and (min-width: 768px) {
    max-width: 276px;
    display: block;
    margin-top: 20px;
    width: 100%;
    align-self: center;
}
`
export const MidHR = styled.div`
margin: 36px;
height: 0;
opacity: 0.6;
border-top: 2px solid ${disabledGrey};
margin-top: 20px;
@media screen and (max-width: 768px) {
    margin: 16px;
}
`
export const Title = styled.h2`
font-weight: 700;
font-size: 36px;
line-height: 44px;
color: ${white};
margin-bottom: 0px;
text-align:center;
@media (max-width: 768px) {
    font-size: 24px;
    line-height: 32px; 
    padding:0 16px;
}
`