import styled from 'styled-components'
import {black, white} from 'tcp-common/lib/colors'
import Flex from 'tcp-common/lib/components/Flex'

export const RewardWrapper = styled(Flex)`
background-color:${black};
width:100%;
@media (min-width: 768px) {
    margin: 0px auto; 
}
@media (max-width: 768px) {
    padding:0px;

}
`
export const RewardContent = styled(Flex)`

@media (min-width: 768px) {
    align-items:center;
    justify-content:center;
    margin: 64px auto 0px; 
    max-width:1024px;
    width:100%;
}
@media (max-width: 768px){
    max-width:100%;
    padding:16px 16px 0;
}
`

export const Title = styled.h2`
font-weight: 700;
font-size: 36px;
line-height: 44px;
color: #D6E3FF;
margin-top:0px;
margin-bottom: 40px;
@media (max-width: 768px) { 
    font-size: 28px;
    line-height: 32px; 
    margin-bottom: 16px;
    text-align:center;
}
`
export const ItemTitle = styled.h2`
font-weight: 700;
font-size: 24px;
line-height: 32px;
color: ${white};
margin-bottom:24px;
margin-top:0px;
@media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px; 
    margin-bottom: 16px;
}
`
export const ItemSubtitle = styled.h2`
font-weight: 400;
font-size: 18px;
line-height: 24px;
color: #E4E4E4;
margin:0px;
@media (max-width: 768px) {
    font-size: 12px;
    line-height: 16px; 
}
`
export const ShowMoreButton = styled.button`
font-weight: 600;
font-size: 16px;
line-height: 24px;
color: #DEB7FF;
margin:0px;
border-radius:8px;
background: none;
border: 1px solid rgb(222 183 255 / 24%);
width: 100%;
padding: 8px;
font-family: 'ProximaNovaA';
`