export const brands=["<img src='./images/airasia.svg' loading='lazy' alt='AirAsia'/>",
"<img src='./images/BigBasket.svg' alt='BigBasket' loading='lazy'/>",
"<img src='./images/croma.svg' alt='Croma' loading='lazy'/>",
"<img src='./images/tata-cliq.svg' alt='Tata Cliq' loading='lazy'/>",
"<img src='./images/tata-1mg.svg' alt='Tata 1mg' loading='lazy'/>",
"<img src='./images/tatasky.svg' alt='Tata Play'loading='lazy'/>",
"<img src='./images/titan.svg' alt='Titan' loading='lazy'/>",
"<img src='./images/tanishq.svg' alt='Tanishq' loading='lazy'/>",
"<img src='./images/westside.svg' alt='WestSide' loading='lazy'/>",
"<img src='./images/ihcl.svg' alt='IHCL, Taj, SelecQtions, Vivanta, Ginger' loading='lazy'/>",
"<img src='./images/cult-fit-black.svg' alt='Cultfit, Curefit, cure.fit' loading='lazy'/>",
"<img src='./images/zudio.svg' alt='Zudio' loading='lazy'/>",
"<img src='./images/qmin.svg' alt='Qmin' loading='lazy'/>",
"<img src='./images/mia.svg' alt='Mia' loading='lazy'/>",
"<img src='./images/fastrack.svg' alt='Fastrack' loading='lazy'/>",
"<img src='./images/caratlane.svg' alt='Caratlane' loading='lazy'/>",
"<img src='./images/tata-cliq-luxury-dark.svg' alt='Tata Cliq Luxury' loading='lazy'/>",
"<img src='./images/helios.svg' alt='Helios' loading='lazy'/>",
"<img src='./images/zoya.svg' alt='Zoya' loading='lazy'/>",
"<img src='./images/airindialogo.svg' alt='Air India' loading='lazy'/>",
]