import styled from 'styled-components'
import {codGray, tertiaryYellow, white} from 'tcp-common/lib/colors'
import Flex from 'tcp-common/lib/components/Flex'
import {purpleGradient} from 'tcp-common/lib/gradient'


export const RewardWrapper = styled(Flex)`
background-color:${codGray};
width:100%;
@media (min-width: 768px) {
    margin: 0px auto; 
}
@media (max-width: 768px) {
    padding:24px 0px;

}
`
export const RewardContent = styled(Flex)`

@media (min-width: 768px) {
    align-items:center;
    justify-content:space-between;
    margin: 64px auto; 
    max-width:1024px;
    width:100%;  
}
@media (max-width: 768px){
    max-width:100%;
}
`
export const RupeeView = styled(Flex)`
align-items: center;
`
export const RightContent = styled(Flex)`

max-width: 45%;
text-align: left;
justify-content: center;
@media (max-width: 768px) {
    padding: 24px 16px 0;
    max-width: 100%;
}
`
export const Subheader = styled.span`
font-weight: 700;
font-size: 36px;
line-height: 44px;
color: ${tertiaryYellow};
@media (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
}
`
export const Subheader1 = styled.span`
font-weight: 700;
font-size: 40px;
line-height: 44px;
margin-top: 24px;
background: ${purpleGradient};
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
@media (max-width: 1024px) {
    font-size: 24px;
    line-height: 32px;
    margin-top: 16px;
}
`
export const RupeeEqual = styled.span`
font-weight: 700;
font-size: 40px;
line-height: 44px;
margin-top: 30px;
background: ${white};
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
@media (max-width: 1024px) {
    font-size: 24px;
    line-height: 32px;
    margin-top: 16px;
}
`
export const RewardImage = styled.img`
object-fit: contain;
height: auto;
@media(max-width:768px){
    width:100%;
    max-width:100%;
}
@media (min-width: 768px) {
    border-radius: 20px;
}
`
export const SmallText = styled.span`
font-weight: 700;
font-size: 32px;
line-height: 40px;
color: ${white};
margin-bottom:24px;
@media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 16px;
}
`
export const Title = styled.h2`
font-weight: 700;
font-size: 36px;
line-height: 44px;
color: ${white};
margin-top:0px;
margin-bottom: 24px;
@media (max-width: 768px) {
    font-size: 24px;
    line-height: 32px; 
    margin-bottom: 16px;
}
`