import isMobile from "tcp-common/lib/utils/is-mobile"
export const getPlatform = () => {

    const userAgent = window?.navigator?.userAgent
  
    const isWebView = /(Version\/\d+.*\/\d+.0.0.0 Mobile|; ?wv|(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari))/i.test(
      userAgent
    )
  
    if (isWebView) {
      return 'webview'
    }
    return 'web'
  }

export const returnPlatform = () => {
    if (typeof window !== "undefined" && window.localStorage.getItem("platform") === "native") {
      const platform = getPlatform()
      switch (platform) {
        case 'ios':
          return "iOS"
        case 'android':
          return "Android"
        default:
          return isMobile() ?  "Mobile PWA":"PWA"
      }
    } else {
      return isMobile() ?  "Mobile PWA":"PWA"
    }
  }
