import { Fragment } from "react";
import { Stack, Box } from "@mui/material";
import { RewardContent, RewardWrapper, Title } from "./styles";
import isMobile from "tcp-common/lib/utils/is-mobile";
import { greyBackgroundGradient } from "tcp-common/lib/gradient";
import { ItemTitle, ItemSubtitle } from "./styles";
//import { ShowMoreButton } from "./styles";

const BenefitsSection = () => {
  const isMobileScreen = isMobile();
  //const [showAllBenefits, setShowAllBenefits] = useState(false);
  const items = [
    // {
    //   imageUrl: "./images/benefits/credit_card_logo.svg",
    //   title: "Got India’s Best Credit Card ?",
    //   subtitle:
    //     "Discover the expectional advantages of the Tata Neu HDFC Bank Credit Card with zero joining fee",
    // },
    {
      imageUrl: "./images/benefits/money_logo.svg",
      title: "Does Your Card Give you Up to 10% Savings?",
      subtitle:
        "Enjoy savings of up to 10% on Tata Neu app",
    },
    {
      imageUrl: "./images/benefits/gift_logo.svg",
      title: "One Card that Rewards you for every Transaction",
      subtitle:
        "Enjoy savings of up to 1.5% on your everyday spends",
    },
    {
      imageUrl: "./images/benefits/category_logo.svg",
      title: "Categories - E-commerce, Bill Payments, Insurance, UPI Payments",
      subtitle:
        "Benefits across various categories including, e-commerce, bill payments, insurance, & UPI payments",
    },
    {
      imageUrl: "./images/benefits/flight_logo.svg",
      title: "Complimentary Lounge Access",
      subtitle:
        "Enjoy up to 12 Airport Lounge visits with no spend required on your NeuCard",
    },
    {
      imageUrl: "./images/benefits/award_logo.svg",
      title: "Most Transparent Rewards Structure where 1 NeuCoin = 1 Rupee",
      subtitle:
        "Benefit from the most transparent rewards system where 1 NeuCoin equals 1 Rupee",
    },
    {
      imageUrl: "./images/benefits/wallet_logo.svg",
      title: "Available on RuPay & Visa Networks",
      subtitle:
        "Use your card with a wide variety of merchants and services, and enjoy the flexibility to choose between Visa and Rupay networks",
    },
  ];
  const mobileItems = [
    // {
    //   imageUrl: "./images/benefits/credit_card_logo.svg",
    //   title: "Got India’s Best Credit Card ?",
    //   subtitle:
    //     "Discover the expectional advantages of the Tata Neu HDFC Bank Credit Card with zero joining fee",
    // },
    {
      imageUrl: "./images/benefits/money_logo.svg",
      title: "Does Your Card Give you Up to 10% Savings?",
      subtitle:
        "Enjoy savings of up to 10% on Tata Neu app",
    },
    {
      imageUrl: "./images/benefits/gift_logo.svg",
      title: "One Card that Rewards you for every Transaction",
      subtitle:
        "Enjoy savings of up to 1.5% on your everyday spends",
    },
    {
      imageUrl: "./images/benefits/category_logo.svg",
      title: "Categories - E-commerce, Bill Payments, Insurance, UPI Payments",
      subtitle:
        "Benefits across various categories including, e-commerce, bill payments, insurance, & UPI payments",
    },
    {
      imageUrl: "./images/benefits/award_logo.svg",
      title: "Most Transparent Rewards Structure where 1 NeuCoin = 1 Rupee",
      subtitle:
        "Benefit from the most transparent rewards system where 1 NeuCoin equals 1 Rupee",
    },
    {
      imageUrl: "./images/benefits/flight_logo.svg",
      title: "Complimentary Lounge Access",
      subtitle:
        "Enjoy up to 12 Airport Lounge visits with no spend required on your NeuCard",
    },
    {
      imageUrl: "./images/benefits/wallet_logo.svg",
      title: "Available on RuPay & Visa Networks",
      subtitle:
        "Use your card with a wide variety of merchants and services, and enjoy the flexibility to choose between Visa and Rupay networks",
    },
  ];
  const benefitItems=isMobileScreen?mobileItems:items;
  const renderItem = (item,index) => {
    const { title, subtitle, imageUrl } = item;
    return (
      <Stack
        sx={{
          borderRadius: "20px",
          padding: isMobileScreen ? "32px 16px 16px" : "64px 24px 24px",
          background: greyBackgroundGradient,
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Stack
            sx={{
              position: "absolute",
              bottom: isMobileScreen ? "12px" : "24px",
            }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <img
              src={imageUrl}
              width={isMobileScreen ? "60px" : "80px"}
              height={isMobileScreen ? "60px" : "80px"}
              alt="logo"
            />
          </Stack>
        </Box>
        <ItemTitle>{title}</ItemTitle>
        {isMobileScreen?null:<ItemSubtitle>{subtitle}</ItemSubtitle>}
      </Stack>
    );
  };
  const renderView = () => (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: isMobileScreen ? "48% 48%" : "50% 50%",
        columnGap:isMobileScreen ?"16px": "32px",
        rowGap: isMobileScreen ? "48px" : "80px",
        marginTop: "42px",
        marginBottom: isMobileScreen ? "16px" : "32px",
        justifyContent: "center",
      }}
    >
      {benefitItems?.map((item, index) => (
        <Fragment
          key={`item-${index}`}
        >
          {renderItem(item,index)}
        </Fragment>
      ))}
      {/* <Box>
        {isMobileScreen && !showAllBenefits && (
          <ShowMoreButton onClick={() => setShowAllBenefits(true)}>
            Show more Benefits
          </ShowMoreButton>
        )}
      </Box> */}
    </Box>
  );
  return (
    <RewardWrapper flexDirection="column">
      <RewardContent flexDirection="column">
        <Title>Benefits with Tata Neu HDFC Bank Credit Card</Title>
        {renderView()}
      </RewardContent>
    </RewardWrapper>
  );
};
export default BenefitsSection;
