import {
    CALCULATOR_URL,
    INTERESTED_URL,
    WEBVIEW_INTERESTED_URL,
    DESKTOP_JOURNEY_URL,
    QUERY_STRINGS
} from "../../Constant"
import { callFireWidgetForAnalytics } from "./Analytics"
import { getPlatform } from "./getPlatform"
const getQueryString = () => {
    const queryString = new URLSearchParams(
        window?.location?.search
    )?.toString()
    return queryString
}
export const redirectProductDetail = (title) => {
    const queryString = getQueryString()
    callFireWidgetForAnalytics(title, "Apply Now")
    let url=`${INTERESTED_URL}&${queryString?queryString:QUERY_STRINGS}`;
    if (getPlatform() === "webview") {
        url=`${WEBVIEW_INTERESTED_URL}?${queryString?queryString:QUERY_STRINGS}`
        window.open(url)
    } else {       
        window.open(url, "_self")
    }
}
export const redirectDownload = (title) => {
    callFireWidgetForAnalytics(title, "Apply Now")
    const queryString = getQueryString()
    const url = queryString
        ? `${DESKTOP_JOURNEY_URL}?${queryString}`
        : `${DESKTOP_JOURNEY_URL}?${QUERY_STRINGS}`
    window.open(url, "_self")
}
export const redirectCalculator = () => {
    window.open(CALCULATOR_URL, "_self")
}
