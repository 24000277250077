import React, { useEffect, useState } from "react";
import {
  ButtonText,
  InterestedButton,
  LeftContent,
  MainContent,
  MainImage,
  MainWrapper,
  RightContent,
  SmallText,
  Subheader,
  Title,
  Wrapper,
  FixedButton,
  ButtonContainer,
} from "./styles";
import isMobile from "tcp-common/lib/utils/is-mobile";
import {
  redirectProductDetail,
  redirectDownload,
} from "../utility/commonMethods";
import { Stack, Typography, Box } from "@mui/material";

const MainSection = () => {
  const isMobileView = isMobile();
  const [header, setHeader] = useState(false);

  const listenScrollEvent = (event) => {
    if (window.scrollY < 500) {
      return setHeader(false);
    } else if (window.scrollY > 600) {
      return setHeader(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);
  const getButton = () => (
    <Wrapper>
      <ButtonContainer>
        <FixedButton
          onClick={
            isMobileView
              ? () => redirectProductDetail("Joining & Renewal Fees")
              : () => redirectDownload("Joining & Renewal Fees")
          }
        >
          <ButtonText>Apply Now</ButtonText>
        </FixedButton>
      </ButtonContainer>
    </Wrapper>
  );
  const navItem = (title) => (
    <Typography
      style={{
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#DEB7FF",
        fontFamily: "ProximaNovaA",
        "&:hover": {
          fontWeight: 700,
        },
      }}
    >
      {title}
    </Typography>
  );
  const renderTopNav = () => (
    <Stack
      direction="row"
      sx={{ width: "100%", my: 2 }}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Box sx={{ paddingLeft: 2 }}>
        <img src={"./images/nav_logo.svg"} alt="tata neu" />
      </Box>
      <Stack
        direction="row"
        sx={{ width: "100%" }}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <Stack sx={{ px: 3 }}>
          <a
            href="#benefits"
            style={{
              textDecoration: "none",
            }}
          >
            {navItem("Benefits")}
          </a>
        </Stack>

        <Stack sx={{ px: 3 }}>
          <a
            href="#faqs"
            style={{
              textDecoration: "none",
            }}
          >
            {navItem("FAQs")}
          </a>
        </Stack>
        <Stack
          sx={{ px: 3, cursor: "pointer" }}
          onClick={() => redirectDownload("Apply Now Navbar")}
        >
          {navItem("Apply Now")}
        </Stack>
      </Stack>
    </Stack>
  );
  const renderDesktopView = () => (
    <MainWrapper>
      <MainContent flexDirection="column">
        {renderTopNav()}
        <MainContent flexDirection="row">
          <LeftContent flexDirection="column">
            <Title>Tata Neu HDFC Bank Credit Card</Title>
            <Subheader>
            Up to 10% savings everytime you shop!
            </Subheader>
            <SmallText>
              {`Got India's Best Credit Card?  With this Credit Card, you can earn rewards for every spend and save 10% on all your purchases. Why wait? Apply for NeuCard, the most searched, top Credit Card in India.`}
              <Stack direction="row" alignItems={"center"} sx={{ my: 2 }}>
                <img
                  src={"./images/coin.svg"}
                  alt="zero joining fee"
                  style={{ marginRight: "16px" }}
                />
                Zero Joining Fee
                <Box sx={{ marginRight: "16px" }} />
                <img
                  src={"./images/gift.svg"}
                  alt="extraordinary rewards"
                  style={{ marginRight: "16px" }}
                />
                Extraordinary Rewards
              </Stack>
            </SmallText>
            {/* <SmallText>{`This is invite only programme. We will open soon for all. Stay tuned!`}</SmallText> */}
            <InterestedButton
              onClick={() => redirectDownload("Joining & Renewal Fees")}
            >
              <ButtonText>Apply Now</ButtonText>
            </InterestedButton>
          </LeftContent>
          <RightContent>
            <MainImage
              src="./Microsite Wireframe Desktop/Neucard-banner-cropped.jpg"
              alt="Tata Neu Credit Cards"
              fetchPriority="high"
              width="520"
              height="520"
            ></MainImage>
          </RightContent>
        </MainContent>
      </MainContent>
      {header && getButton()}
    </MainWrapper>
  );

  const renderMobileView = () => (
    <MainWrapper flexDirection="column">
      <MainImage
        src="./Microsite Wireframe Mobile/Neucard Mobile banner 1 - 360x360.webp"
        alt="Tata Neu Credit Cards"
        fetchPriority="high"
        width="360"
        height="360"
      ></MainImage>
      <LeftContent flexDirection="column">
        <Title>Tata Neu HDFC Bank Credit Card</Title>
        <Subheader>
        Up to 10% savings everytime you shop!
        </Subheader>
        <SmallText>{`Got India's best Credit Card?  With this Credit Card, you can earn rewards for every spend and save 10% on all your purchases. Why wait? Apply for NeuCard, the most searched, top Credit Card in India.`}
        </SmallText>
        <Stack direction="column" sx={{ mt: 2 }}>
          <Stack direction="row" alignItems={"center"} sx={{mb:2}}>
            <img
              src={"./images/coin.svg"}
              alt="zero joining fee"
              style={{ marginRight: "16px" }}
              width="40px"
              height="40px"
            />
            <SmallText>Zero Joining Fee</SmallText>
          </Stack>
          <Stack direction="row" alignItems={"center"}>
            <img
              src={"./images/gift.svg"}
              alt="extraordinary rewards"
              style={{ marginRight: "16px" }}
              width="40px"
              height="40px"
            />
            <SmallText>Extraordinary Rewards</SmallText>
          </Stack>
        </Stack>
        {/* <SmallText>{`This is invite-only programme.`}</SmallText> */}
      </LeftContent>
      {getButton()}
    </MainWrapper>
  );

  const renderView = () =>
    isMobileView ? renderMobileView() : renderDesktopView();

  return renderView();
};

export default MainSection;
