import React from 'react'
import { 
    DetailsContent, 
    MainContent, 
    SectionImage, 
    MainWrapper, 
    DetailHeaderLeft, 
    DetailHeaderRight, 
    DetailContentHeader,
    MidHR,
    Title
} from './styles';
import isMobile from 'tcp-common/lib/utils/is-mobile'
import Card from './Card';
import { Box } from '@mui/material';

const DetailsSection = () => {
    const isMobileView = isMobile()

    const cardsContent = () => <>
      <Card 
        left="10%"
        right="7%"
        centerMain="NeuCoins rewards on Tata Neu" 
        centerSecondary="(Inclusive of 5% NeuCoins with NeuPass Membership  on select categories)"
      />
      <MidHR />
      <Card 
        left="5%"
        right="2%"
        centerMain="NeuCoins rewards at partner Tata brands"
        centerSecondary="(Online and at Stores)"
        centerImage={isMobileView ? "./Microsite Wireframe Mobile/Neucard Mobile banner 4 1068X200.png" : "./Microsite Wireframe Desktop/Neucard banner 4 1068X200.png"}
      />
      <MidHR />
      <Card 
        left="5%"
        right="2%"
        centerMain="NeuCoins rewards on every bill payment via Tata Neu" 
      />
      <MidHR />
      <Card 
        left="1.5%"
        right="1%"
        centerMain="On Tata Neu UPI ID Spends" 
        centerSecondary="(only RuPay Cards)"
      />
      <MidHR />
      <Card 
        left="0.5%"
        right="0.25%"
        centerMain="On other UPI spends*" 
        centerSecondary="(w.e.f 1st Aug 24)"
      />
      <MidHR />
      <Card 
        left="1.5%"
        right="1%"
        centerMain="NeuCoins Rewards on all other spends*" 
        centerSecondary="(*Excluding wallet load, fuel, rent, govt. spends)"
      />
      <MidHR />
      <Card 
        left="8 domestic & 4 international per annum"
        right="4 domestic
        per annum"
        centerMain="Complimentary airport lounge access" 
      />
      <MidHR />
      <Card 
        left="1%"
        right="1%"
        centerMain=" Fuel Surcharge waiver" 
      />
      <MidHR />
    </>

    const renderDesktopView = () => <MainWrapper>
        <MainContent flexDirection='column'>
        <Box align={"center"} p={2}>
          <Title>NeuCard Rewards with NeuCoins</Title></Box>
            <SectionImage 
              src='./Microsite Wireframe Desktop/Neucard-banner-3-1142X460.webp'
              alt='Tata NeuCard Infinity Vs NeuCard Plus'
              width="1024"
              height="412"
            ></SectionImage>
            <DetailsContent flexDirection='column'>
           
                <DetailContentHeader flexDirection='row' justifyContent='space-between'>
                  <DetailHeaderLeft>NeuCard Infinity</DetailHeaderLeft>
                  <DetailHeaderRight>NeuCard Plus</DetailHeaderRight>
                </DetailContentHeader>
                {cardsContent()}
                {/*<InterestedButton onClick={()=>redirectProductDetail('Details Section')}>
                    <ButtonText>Apply Now</ButtonText>
</InterestedButton>*/}
            </DetailsContent>
        </MainContent>
    </MainWrapper>

    const renderMobileView = () => <MainWrapper flexDirection='column'>
      <Title>NeuCard Rewards with NeuCoins</Title>
            <SectionImage 
              src='./Microsite Wireframe Mobile/Neucard Mobile banner 3 - 360x360.webp'
              alt='Tata NeuCard Infinity Vs NeuCard Plus'
              width="360"
              height="360"
            ></SectionImage>
            <DetailsContent flexDirection='column'>
                <DetailContentHeader flexDirection='row' justifyContent='space-between'>
                  <DetailHeaderLeft>NeuCard Infinity</DetailHeaderLeft>
                  <DetailHeaderRight>NeuCard Plus</DetailHeaderRight>
                </DetailContentHeader>
                {cardsContent()}
                {/*<InterestedButton onClick={()=>redirectProductDetail('Details Section')}>
                    <ButtonText>Apply Now</ButtonText>
</InterestedButton>*/}
            </DetailsContent>
    </MainWrapper>

    const renderView = () => isMobileView ? renderMobileView() : renderDesktopView()
    
    return renderView();
}

export default DetailsSection
